// Libs.
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// Deps.
import { Drawer } from '@material-ui/core';
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';
import UserResetPassword from '../../components/user/user-reset-password';

import styles from '../../components/user/user-login.module.scss';
import { useStateValue } from '../../store/state';

const PasswordResetPage = ({ location: { search = '' } }) => {
  const qs = new URLSearchParams(search);
  const token = qs.get('t') || '';
  const [state] = useStateValue();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const changeDrawer = (loginStatus) => {
    setUserLoggedIn(loginStatus);
    setDrawerOpen(loginStatus);
  };

  useEffect(() => {
    changeDrawer(!isEmpty(state.user.access_token));
  }, []);

  return (
    <AppLayout>
      <SEO title="User Password Reset"/>
      <UserResetPassword token={token} drawer={{ changeDrawer: changeDrawer }}/>
      <Drawer
        open={!!drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={styles.drawerWrapper}
        variant={'temporary'}>
        <div className={styles.drawerContainer}>
        </div>
      </Drawer>
    </AppLayout>
  );
};

export default PasswordResetPage;
